.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: linear-gradient(to right, rgb(34, 31, 31), #2a2a3a);
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    height:400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: relative;
  }
  .modal-content h3{
    text-align: center;
    margin-bottom: 30px;    
}
 
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: white;
    transition: transform 0.2s, color 0.2s;
  }
  
  .modal-close:hover {
    color: #ff0000;
    transform: scale(1.1);
  }
  
  .modal-input {
    display: grid;
    align-items: center;
    grid-template-columns: 70% 30%;
    border-radius: 25px;
    border: 1px solid rgb(98, 98, 98);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .modal-input input {
    flex-grow: 1;
    border: none;
    background: transparent;
    outline: none;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
  }
  
  .modal-input button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  .fa-whatsapp-btn{
    color: #25D366;
    font-size: 20px;
    padding: 10px 20px;

  }
  .fa-whatsapp-btn i{
    font-size: 25px;
    margin-right: 5px;
  }
  .modal-email-button{
    color: rgb(26, 143, 207);
    font-size: 19px;
    padding: 10px 16px;
  }
  .modal-email-button i{
    font-size: 22px;
    margin-right: 7px;
    margin-top:2px;
  }
 