.ContactUsSection{
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    height:500px

}
/* .ContactUsSectionHeader{
    color: white;
    font-size:70px
} */
.ContactUsSectionP{
    color: rgb(187, 187, 187);
    margin-top:30px;
    margin-bottom:30px;
    font-size: 18px;
}
.ContactUsSection img {
    position: absolute;
    z-index: -1;
    max-width: 100%;
    height:500px;
    object-fit: cover;
}


@media (max-width: 750px) {
    
    /* .ContactUsSection{

       height:410px;
    
    }
    .ContactUsSection img {
        height:410px;
    } */
    
  }@media (max-width: 550px) {
    .ContactUsSectionP{
        font-size: 14px;
    }

    .ContactUsSectionP{
        max-width: 280px;
    }
  }