.WhatWereGoodAtContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.WhatWereGoodAtContent {
  padding: 40px 20px;
  width: 80%;
  max-width: 1200px;
}

.WhatWereGoodAtContent .header {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 40px;
}

.skillList {
  width: 100%;
}

.skillItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 0.01rem solid #5a5a5a;
}

.skillNumber {
  font-size: 24px;
  font-weight: bold;
  color: #888;
  margin-right: 20px;
  min-width: 40px;
}

.skillContent {
  flex-grow: 1;
}

.skillTitle {
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 10px 0;
}

.skillDescription {
  font-size: 16px;
  color: #c2c2c2;
  line-height: 1.5;
  max-width: 100%;
  margin-bottom: 20px;
}

.skillItemHeader {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.skillItem .arrow-button { display: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: 1px solid #d0d0d0;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.skillItem .arrow-button:hover {
  background-color: #e0e0e0;
}

.skillItem .arrow {
  font-size: 15px;
  color: #333;
}
.uiuxcolor{
  position: relative;
}
.skillItem p{
  max-width: 700px;
}
.uiuxcolor .skillNumber {
  color: #BAA7E9 !important;
}

.uiuxcolor .skillTitle {
  color: #BAA7E9 !important;
}

.skill-img { display: none;
  max-width: 140px;
  height: 180px;
  border-radius: 5px;
  transform: rotate(-15deg);
  object-fit: cover;
  margin-left: auto;
  position: absolute;
  right:0px;
  margin-bottom: -40px;
}
@media (max-width:1080px) {
  .skillItem p{
    max-width: 400px;
  }
}
@media (max-width: 768px) {
  .WhatWereGoodAtContent {
    width: 95%;
    padding: 20px 10px;
  }

  .WhatWereGoodAtContent .header {
    font-size: 36px;
    margin-bottom: 30px;
  }

  .skillTitle {
    font-size: 20px;
  }

  .skillDescription {
    font-size: 14px;
  }

  .skill-img {
    max-width: 100px;
    height: 120px;
  }
}

@media (max-width: 550px) {
  .WhatWereGoodAtContent .header {
    font-size: 28px;
  }

  .skillNumber {
    font-size: 20px;
  }
  .skillItem p{
    max-width: 300px;
  }
  .skillTitle {
    font-size: 18px;
  }

  .skillDescription {
    font-size: 13px;
  }

  .skill-img {
    max-width: 80px;
    height: 100px;
  }
}
@media (max-width: 400px) {
  .skillItem p{
    max-width: 150px;
  }
}