.Services-Container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Services-Container-P{
    color: #969696;
    line-height: 30px;
}
.Services-Left{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Services-Right{
    display: flex;
    flex-direction: column;
}
.Services-Right p {
    margin-bottom: 35px;
}
.Services-Container-Options{
    display: flex;
    flex-direction: column;
    max-width:600px;
}
.Services-Container-Options-Container{
    justify-content: space-between;
    display: flex;
    align-items: center;
}
.LaptopImg{
    height:600px;
    object-fit: cover;
}
.Services-Options-Box-Header-Number{
    color:#5E5E5E;
    font-size:35px;
    margin-bottom: 5px;
}
.Services-Options-Box-Header{
    color:#B2B2B2;
    font-size:42px;
    margin-bottom: 20px;
}
.Services-Options-Box{
    margin-bottom: 80px;
}
.Services-Options-Box-2{
    padding-left:30px;
}
.Services-Options-Box-2 .Services-Options-Box-Header, .Services-Options-Box-2 .Services-Options-Box-Header-Number{
    color:#ffffff !important;

}

.background-options{
    background-color: #191919;
    width: 67%;
    height:230px;
    margin-top:-30px;
    position: absolute;
    left:0px;
    z-index: -1;
}
.LaptopImgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.LaptopImgDiv button{
    position: absolute !important;
}
@media (max-width: 1040px) {
    .Services-Container{
        flex-direction: column;
    }
    .Services-Container-Options-Container{
        flex-direction: column;
    }  
    .LaptopImgDiv{
        display: none;
    }
    .background-options{
        width:100%;
        height:260px;
    }
    .Services-Left .SectionName{
       margin-bottom: -60px;

    }
    .Services-Left h2{
        margin-bottom:20px;
    }
}
@media (max-width: 520px) {
    .Services-Left .SectionName{
display: none;     
}

}
@media (max-width: 400px) {
    .background-options{
        width:100%;
        height:290px;
    }
   
}