.ContactUSBtn {
    display: inline-block;
    padding: 10px 22px;
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 9999px; /* Large value to ensure pill shape */
    font-family: Arial, sans-serif;
    font-size: 17px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-weight: 600;
  }
  
  .ContactUSBtn:hover {
    background-color: black;
    color: white;
  }