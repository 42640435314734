/* src/Navbar.css */
.navbar {
  height: 110px;
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 20px;    background-color: rgba(0, 0, 0, 0.9);

}

.navbar * {
  z-index: 1001;
}

.nav-links {
  width: 30%;
  display: flex;
  justify-content: space-between;
}

.nav-menu {
  display: flex;
  background-color: transparent;
  padding: 10px;
}

.nav-item {
  color: #999;
  padding: 10px 25px;
  text-decoration: none;
  font-family: Arial, sans-serif;
  font-size: 16px;
  transition: all 0.3s ease;
}

.nav-item.active {
  background-color: #555;
  color: white;
  border-radius: 20px;
}

.nav-item:hover {
  color: white;
}

.nav-phone {
  display: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}
.contact-us-navbar-link{
  display: none;
}
@media (max-width: 1018px) {
  .nav-phone {
    display: block;
  }.contact-us-navbar-link{
    display: block;
  }
  .contact-us-navbar {
    display: none;
  }
  .nav-menu {
    display: flex;
    position: fixed;
    top: -100%;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 20px;
    transition: top 0.3s ease-in-out;
  }

  .nav-menu.open {
    top: 110px;
  }

  .nav-item {
    padding: 15px 25px;
    text-align: center;
  }

  .navbar button {
    display: none;
  }
}

@media (max-width: 500px) {
  .nav-phone {
    font-size: 20px;
  }
/* src/Navbar.css */
/* .navbar {
  background-color: black;
} */
}