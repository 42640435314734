.SectionName{
    background-color: #191919;
    padding:20px;
    transform: rotate(-90deg); /* Rotates the element by 45 degrees */
    white-space: nowrap;
    position: absolute;
    font-size: 20px;
    left:-45px;
    width:150px;
    color: #969696;
    display: flex;
    align-items: center;
    justify-content: center;
}
