.PortfolioSection{
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: white;
}
 .PortfolioSection h3{
    color:black !important;
}
.PortfolioSection .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  .PortfolioSectionHeader{
    margin-bottom: 60px;
    display: flex;
    align-items: center;
  }
  .PortfolioSection .title {
    font-size: 42px;
    font-weight: bold;
    max-width: 800px;
    color:#000;
  }
  .PortfolioSection .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
  
  .PortfolioSection .product {
    border-radius: 10px;
    position: relative;
    overflow: hidden;
  }
  
  .productImage {
    width: 100%;
    object-fit: cover;
    max-height: 400px;
    border-radius: 16px;
  }
  
  .productContent {
    padding: 20px;
  }
  
  .PortfolioSection .productTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .productDescription {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .PortfolioSection .tags {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  .PortfolioSection .tags span {
    cursor: pointer;
  }
  .PortfolioSection .tags div {
    display: flex;
    gap:20px;
    align-items: center;
  }
  
  .PortfolioSection .tag {
    border: 1px solid rgb(155, 155, 155);
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;
  }
  
  .PortfolioSection .button {
    background-color: #000;
    color: #fff;
    border-radius: 20px;
    border: none;
    padding: 10px 20px;
     cursor: pointer;
  }

  @media (max-width: 900px) {
    .PortfolioSection .grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 40px;
      }
      .PortfolioSection .tags {
        flex-direction: column;
        gap: 30px;
        margin-bottom: 20px;
      }
  }
  