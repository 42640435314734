.blog-post-includes {
    background-color: #000000;
    height: auto;
    min-height: 350px;
    margin: 15px;
    position: relative;
    border-radius: 9px;
    border: 0.01rem solid rgb(28, 28, 28);
    padding-bottom: 30px;   
  }
  
  .post-img-includes {
    border-radius: 9px 9px 0 0;
    width: 100%;
    height: 170px;
    object-fit: cover;
  }
  
  .post-title-includes {
    font-size: 16px;
    padding: 20px;
  }
  
  .read-more-btn {
    color: #004c98 !important;
    position: absolute;
    bottom: 15px;
    left: 25px;
    font-size: 14px;
  }
  
  .read-more-btn:hover {
    color: #0270e0 !important;
  }
  
  .post-title-p-includes {
    margin-top: 10px;
    margin-bottom: 15px;
    color: #ffffff;
    font-size: 22px;
    font-weight: bold;
    width: 80%;
  }
  
  .post-content-date {
    color: rgb(230, 230, 230);
    font-size: 14px;
  }
  
  .post-content-preview {
    font-size: 16px;
    line-height: 26px;
    color: #878787;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  
  .blog-post-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px; /* Adjust the gap between columns if needed */
  }
  
  /* Media Queries */
  @media (max-width: 1024px) {
    .blog-post-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .blog-post-container {
      grid-template-columns: 1fr;
    }
  }
  