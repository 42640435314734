.SectionNameLight{
    background-color: #E7E7E7;
    padding:20px;
    transform: rotate(-90deg); 
    text-align: center;
    white-space: nowrap;
    position: absolute;
    font-size: 18px;
    left:-55px;
    color: #646464;

}
@media (max-width:650px) {
    .SectionNameLight{
        display: none;
      }
}