.about-us-section {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
  }
  
  .about-us-content {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .about-us-p {
    font-size: 24px;
    margin-bottom: 10px;
    color: white;
  }
  
  .about-us-content-p {
    font-size: 19px;
    line-height: 1.5;
    color: white;
    max-width: 600px;
  }
  
  .slider {
    position: relative;
    width: 100%;
    overflow: hidden;
    touch-action: pan-y;
  }
  
  .slider-container {
    display: flex;
    transition: transform 0.3s ease-out;
  }
  
  .slide {
    flex: 0 0 65%;
    padding-right: 25%;
    box-sizing: border-box;
  }
  
  .slide-image img {
    width: 155%;
    height: 310px;
    border-radius: 5px;
    object-fit: cover;
  }
  @media (max-width: 750px) {
    .about-us-content{
      flex-direction: column;
      margin-top: 100px;
    }
  }