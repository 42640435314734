.Logo2 {
    color:white !important;
    font-family: Neutral-face;
    font-size:26px;
    font-weight: 1000;
}.Logo2 *{
    color:white !important;
 
}
@media (max-width: 500px) {
    .Logo2{

        font-size:20px;
    }
  }