.circle-button-div {
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px; 
  position: relative;  
  z-index: 1; 
  width: 80px;
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid white;    z-index: 11122222222 ;
  margin-bottom:80px;
}
.circle-button {
  background: transparent;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  }
.circle-button img {
  height:25px;
}
  .arrow-down {
    
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid #333;
  }
  @media (max-width: 700px) {
    .circle-button-div {
      width: 60px;
      height: 60px;
    }    
    .circle-button img{
      height:19px;
    }
    
  }
  @media (max-width: 350px) {
    .circle-button {
      width: 40px;
      height: 40px;
    }
    .circle-button i{
      font-size: 16px;
    }
    
  }