.HeroSection {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    opacity: 0; /* Start invisible */    height: 680px;

  }
  .HeroImgSqr {
    position: absolute;
    z-index: 4;
    top: 1px;
    width: 100%;
  }
  
  .HeroImg {
    position: absolute;
    margin-top: 300px;
    height: 580px;
    object-fit: cover;
    width: 100%;
    z-index: 2;
  }
  
  .overlay {
    position: absolute;
    margin-top: 300px;
    height: 580px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3));
    z-index: 3;
  }
  
  /* Ensure smooth transitions */
  .HeroSection, .HeroSectionHeaderContainer, .HeroImg, .overlay {
    transition: opacity 1s ease, transform 1s ease;
  }
  .HeroSection {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    /* Remove this line:
    opacity: 0;
    */
  }
  
  /* Rest of your CSS remains the same */
  .HeroSectionHeaderContainer {
    z-index: 5;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 190px;
  }
  
.HeroSectionHeaderContainer div{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top:20px;

}
.HeroSectionHeaderP p{
    color: rgb(187, 187, 187);
    margin-top:10px;
    font-size: 18px;
}
.CircleBtnDiv{
    margin-top:60px !important;
}
.HeroSectionHeader2{
    margin-top:10px;
}
.Corporation-P{
    color: rgb(223, 223, 223);
    margin-top:50px;
    margin-bottom: 50px;
    font-size: 20px;

}/* Add these new styles */
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loading {
    color: #fff;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .counter {
    color: #fff;
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .progress-bar {
    width: 0;
    height: 4px;
    background-color: #fff;
  }
  @media (max-width: 1018px) {
    
    .HeroImg {
      margin-top: 15px;
 
    }
    .overlay {
      margin-top: 15px;
      height: 580px;
      
    }
    
  }
  @media (max-width: 750px) {
  
    .HeroSectionHeaderP p{

      font-size: 14px;

  }.Corporation-P{
    font-size: 16px;

    }
  }
  @media (max-width: 500px) {
    .HeroSection {
   height: 480px !important;
  
    }
    .HeroSectionHeaderP p{

  max-width: 350px;
  }
  .HeroSectionHeaderP-Phone{
    display: block !important;
  }
  .HeroSectionHeaderP-Normal{
    display: none;
  }
  .CircleBtnDiv{
    margin-top:30px !important;
}
  }
  .HeroSectionHeaderP-Phone{
    display: none;
  } 

  @media (max-width: 360px) {    .HeroSection {
    height: 420px !important;
   
     }
    .HeroSectionHeaderP p{
      max-width: 250px;
      font-size: 14px;
  }
    .Corporation-P{
      font-size: 14px;
  
      }
  }
  