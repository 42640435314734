@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@100..900&display=swap');


 @font-face{
  font-family: Neutral-face;
  src:url(assets/fonts/a.otf);
}
.customlogofont  {
  font-family: Neutral-face;
} 
/* @font-face{
  font-family: font1;
  src:url(assets/fonts/Necosmic-PersonalUse.otf);
}
@font-face{
  font-family: font2;
  src:url(assets/fonts/font2/Halfomania-Regular.otf),
  url(assets/fonts/font2/Halfomania-Regular.ttf);

}
@font-face{
  font-family: font4;
  src:url(assets/fonts/font2/Halfomania-Regular.otf),
  url(assets/fonts/font4/Reiswar.ttf);
} */
/* @font-face{
  font-family: font5;
  src:url(assets/fonts/font5/ABSTER.otf),
  url(assets/fonts/font4/Reiswar.ttf);
} */
/* @font-face{
  font-family: font6;
  src:url(assets/fonts/font6/Stopwatch.otf),
  url(assets/fonts/font4/Reiswar.ttf);
} */
 
/* @font-face{
  font-family: sf-pro;
  src:url(assets/fonts/SFPRODISPLAYBLACKITALIC.OTF);
  src:url(assets/fonts/SFPRODISPLAYBOLD.OTF);
  src:url(assets/fonts/SFPRODISPLAYHEAVYITALIC.OTF);
  src:url(assets/fonts/SFPRODISPLAYLIGHTITALIC.OTF);
  src:url(assets/fonts/SFPRODISPLAYMEDIUM.OTF);
  src:url(assets/fonts/SFPRODISPLAYREGULAR.OTF);
  src:url(assets/fonts/SFPRODISPLAYSEMIBOLDITALIC.OTF);
  src:url(assets/fonts/SFPRODISPLAYTHINITALIC.OTF);
  src:url(assets/fonts/SFPRODISPLAYULTRALIGHTITALIC.OTF);
} */
*{
  padding:0;
  margin:0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body{
  font-family: Arial, sans-serif;
  overflow-x: hidden;
    background-color: black;
}

li{
   text-decoration: none;
}

ul {
  list-style: none;
}

button{
  cursor:pointer;
}

a{
  text-decoration: none;
  cursor:pointer;
  transition: 0.3s;
}
a:hover{
  color:var(--main-color-ligher);
}
/* :root {

} */
/* 
#ADD8E6 - Light Blue
#87CEFA - Light Sky Blue
#B0E0E6 - Powder Blue
#AFEEEE - Pale Turquoise
#E0FFFF - Light Cyan */

h1, h2, h3 {
  color:rgb(255, 255, 255) !important;
  font-weight: 700 !important;
}
h1{
  font-size:90px !important;
}
h2{
  font-size:70px !important;
}
h3{
  font-size:44px !important;
}
h4{
  font-size:26px !important;
}
.Container{
  padding-right:7%;
  padding-left:7%;
}
.Container2{
  margin-top:151px;
}
.line{
  margin-top:10px;
  width:90px;
  border: 2.5px solid var(--header-color);
  border-radius: 111111125px;
}
.Offer-Header{
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 1018px) {
  h1{
    font-size:70px !important;
  }
  h2{
    font-size:45px !important;
  }  .Container{
    padding-right:14%;
    padding-left:14%; 
    /* ????? */
  }
  h3{
    font-size:28px !important;
  }
}

@media (max-width: 750px) {
  .Container2{
    margin-top:45px;
  }

  h1{
    font-size:40px !important;
  }
  h2{
    font-size:30px !important;
  }
  h3{
    font-size:21px !important;
  }
}
@media (max-width: 500px) {
  .Container{
    padding-right:5%;
    padding-left:5%; 
    /* ????? */
  }

  h1{
    font-size:30px !important;
  }
}
@media (max-width: 450px) {

  h1{
    font-size:27px !important;
  }
  h2{
    font-size:22px !important;
  }
  h3{
    font-size:18px !important;
  }
}
.overlay{
  background-color: rgba(0, 0, 0, 0.5);
  height:100%;
  width:100%;
  position: absolute;
}