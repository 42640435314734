.ContactForm {
    display: flex;
    min-height: 100vh;
    margin-bottom: 110px !important;
    margin-top: 120px;
  }
  
  .ContactForm .submit {
    width: 100%;
  }
  
  .form-section h3 {
    margin-bottom: 50px;
    margin-top: 40px;
  }
  
  .form-section p:first-of-type {
    margin-bottom: 40px;
  }
  
  .form-section {
    background-color: #111111;
    color: white;
    padding: 40px;
    width: 50%;
    display: flex;
    flex-direction: column;
  }
  
  .form-section h2 {
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: 500;
  }
  
  .form-section p {
    margin-bottom: 30px;
    font-size: 14px;
    color: #a0a0a0;
  }
  
  .form-section input {
    width: 100%;
    padding: 10px;
    margin-bottom: 50px;
    background-color: transparent;
    border: 0px;
    border-bottom: 0.1rem solid rgb(69, 69, 69) !important;
    color: white;
    font-size: 14px;
  }
  
  .form-section .input-group {
    display: flex;
    gap: 15px;
  }
  
  .form-section .country-code {
    display: flex;
    align-items: center;
    background-color: #1e1e1e;
    border-radius: 6px;
  }
  
  .form-section .country-code img {
    width: 20px;
    margin-right: 5px;
  }
  
  .form-section .phone-input {
    display: flex;
    gap: 15px;
  }
  
  .form-section .phone-input input:first-child {
    width: 100%;
  }
  
  .form-section .radio-group {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .form-section .radio-option {
    display: flex;
    align-items: center;
    background-color: #1e1e1e;
    border-radius: 6px;
    padding: 15px;
  }
  
  .form-section .radio-option input[type="radio"] {
    margin-right: 10px;
    width: auto;
  }
  
  .form-section button {
    padding: 12px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .promo-section {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;  background-color: rgba(0, 0, 0, 0.5);

  }
  
  .promo-section-content,
  .promo-info {
    padding-left: 2%;
    padding-right: 5%;
  }
  
  .promo-text-info {
    position: absolute;
    bottom: 100px;
    font-size: 20px;
    font-weight: 600;
    line-height: 2.1rem;
     text-align: center;
  }
  
  .promo-image {
    background: url('../../assets/12421rfdewf.webp') no-repeat center center/cover;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1;
    object-fit: cover;
  }
  
  .promo-text {
    color: #ffffff;
  }
  
  .promo-text .PROFESSION {
    color: transparent;
    margin-top: 10px;
    font-weight: bold;
    font-size: 10px;
  }
  
  .promo-text .company {
    margin-top: 10px;
    color: white !important;
    font-family: font5;
    font-size: 19px;
    font-weight: 1000;
  }
  .promo-text .CEO-Name {
    color: transparent;

  }
  
  .promo-info {
    font-weight: 800;
    position: absolute;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 1024px) {
    .ContactForm {
      flex-direction: column;
    }
  
    .form-section,
    .promo-section {
      width: 100%;
    }
  
    .promo-section {
      min-height: 50vh;
    }
  
    .promo-text-info {
      bottom: 100px;
      font-size: 18px;
      line-height: 2rem;
    }
  }
  
  @media screen and (max-width: 768px) {
    .form-section {
      padding: 20px;
    }
  
    .form-section h3 {
      margin-bottom: 30px;
      margin-top: 20px;
    }
  
    .form-section p:first-of-type {
      margin-bottom: 20px;
    }
  
    .form-section input {
      margin-bottom: 30px;
    }
  
    .promo-text-info {
      bottom: 80px;
      font-size: 16px;
      line-height: 1.8rem;
    }
  
    .promo-info {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
  @media screen and (max-width: 480px) {
    .form-section .input-group {
      flex-direction: column;
      gap: 0;
    }
  
    .promo-text-info {
      bottom: 60px;
      font-size: 14px;
      line-height: 1.6rem;
    }
  
    .promo-text .company {
      font-size: 16px;
    }
  }

  .success-message{
    color: green;
  }
  .error-message{
    color: red;
  }