.Ideas-Container{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}
.Ideas-Container-Header{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Expertise-Container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: linear-gradient(to right, rgba(51, 51, 51, 0.4), rgba(0, 0, 0, 0.5));
    border-radius: 21px;

}
.Expertise-Container-Left p{
    color: #969696;
    line-height: 30px;
    margin-top:30px;
    margin-bottom: 30px;
    width:70%
}
.Expertise-Container-Left {
    width: 60%;    padding: 40px;

}
.Expertise-Container-Right {
    width: 40%;
}
.Expertise-Container-Right img{
    width: 100%;
    height:100%
}
.Expertise-Container-Bottom{
    display: grid;
    margin-top:50px;
    grid-template-columns: repeat(2, 1fr);
    gap:30px;
}
.Expertise-Container-Bottom img{
    max-width: 100%;
    margin-top:30px
}
.Expertise-Container-Bottom-Box p{
    color: #969696;
    line-height: 30px;
    margin-top:30px;
    margin-bottom: 30px;
    width:70%;
}
.Expertise-Container-Bottom-Box{
    background: linear-gradient(to bottom, rgba(58, 58, 58, 0.4), rgba(0, 0, 0, 0.5));
    padding:35px;
    border-radius: 21px;
}

@media (max-width: 1060px) {
    .Expertise-Container-Bottom-Box p{
    
        width:100%;
    }
    .Expertise-Container-Bottom p{
        font-size: 14px !important;
    }
    .Ideas-Container p{
        font-size: 14px !important;
    }
    .Expertise-Container{
        flex-direction: column !important;
    }
    .Expertise-Container-Left p{
        width:100%
    }
    .Expertise-Container-Left {
        width:100%
    }
    .Expertise-Container-Right {
        width:100%
    }
    .Expertise-Container-Bottom{
        flex-direction: column !important;
        display: flex;
    }
    .Expertise-Container-Left{
        flex-direction: column !important;
        display: flex;
        align-items: center;
    }
    .Expertise-Container-Right img{
        margin-top:50px
    }
  }
  @media (max-width:750px) {
    .Expertise-Container-Bottom-Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    }
 
    }
    @media (max-width:550px) {
  .Ideas-Container .SectionName{
    display: none;
  }
  .Ideas-Container-Header-Phone{
    display: block !important;
}  
.Ideas-Container-Header-Normal{
    display: none;
}
        }
    @media (max-width:350px) {
        .Expertise-Container-Bottom-Box{
            padding:25px;
   
        }
        .Expertise-Container{
   
            padding: 20px;
      
        
        }
        .Expertise-Container-Right img{ 
   
display: none;      
        
        }
        }

        .Ideas-Container-Header-Phone{
            display: none;
        }