.arrow-button {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    background-color: black;
    border: none;
    cursor: pointer;
    display: flex;
    border: 1px solid white;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .arrow-button:hover {
    background-color: #333;
  }
  
  .arrow-button svg {
    width: 40px;
    height: 40px;
  }