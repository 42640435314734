.footer {
    background-color: #000;
    color: #fff;
    margin-top:50px
  }
 
  
  .footer-content {
    display: flex;
    justify-content: space-between;
  }
  .footer-logo{
    width: 50%;
  }
  
  .footer-logo h2 {
    margin-bottom: 10px;
  }
  
  .footer-links {
    display: flex;
    justify-content: space-between;
    width: 70%;
  }
  
  .footer-section h3 {
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    list-style-type: none;
  }
  
  .footer-section li {
    color: #898989;
    margin-bottom: 15px;  }
.footer-section p{
    font-size: 18px;
    margin-bottom: 31px;
}
.footer-logo-p{
    margin-top:15px;
    color: #898989;
    font-size: 17px;
    max-width: 220px;
}
.footer-Rights {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: #898989;
  padding-top:30px;
  border-top: 0.01rem solid #8989895c;
  margin-left:7%;
  padding-bottom:30px;
  margin-top:30px;
  margin-right:7%;
}

.footer-Rights a{
  color: #898989;
  margin-right:20px;
}

@media (max-width:1050px) {

  
  .footer-content {
flex-direction: column;
  }
  .footer-links {

    width: 100%;
    margin-top:50px
  }
  .footer-logo-p{

    max-width: 100%;
}
.footer-logo{
  width: 100%;
}

}
@media (max-width:550px) {

  
  .footer-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap:110px;
  align-items: center;
      width: 100%;  place-items: center;

    }
    .footer-Rights {
      flex-direction: column;
 

    }
    .footer-Rights *{
      margin-bottom: 30px;
    }
    .footer-Rights a{
      margin-right: 20px;
    }
    .footer-Rights div{
      margin-right: -20px;

    }
    
}
@media (max-width:350px) {

  
  .footer-links {
    place-items:unset;
    grid-template-columns: repeat(1, 1fr);
    gap:60px;

     }
}
