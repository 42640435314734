.explore-button {
    background-color: transparent;
    border: 1px solid #ffffff; /* Add this line for the border */
    border-radius: 25px;
    padding: 13px 1px;
    font-size: 16px;
    font-weight: bold;    color: white;

    width:215px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.1s;
  }
 
  .explore-button:hover {
    background-color: #333;
  }
  
  .explore-button:active {
    transform: scale(0.98);
  }
  
@media (max-width: 1050px) {
  .explore-button {

    padding: 13px 1px;
    font-size: 14px;
   
    width:190px;
   
  }
}
@media (max-width: 350px) {
  .explore-button {

    padding: 10px 1px;
    font-size: 12px;
   
    width:170px;
   
  }
}
